import { useNavigate } from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '../../apis/auth/useGoogleLogin';


const SocialLoginGoogle = ({redirectTo}) => {
    const loginGoogleUser = useGoogleLogin();
    const navigate = useNavigate();
    const handleLoginSuccess = (response) => {
        const token = response.credential;
        loginGoogleUser(token).then(() => {
            // Redirect to the page the user was trying to access before logging in
            navigate(redirectTo?.pathname);
        });
    };

    return (
        <>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={() => console.log("Login Failed")}
            />
        </>
    );
}

export default SocialLoginGoogle;
