import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import 'primereact/resources/primereact.min.css';

const ReportLoadingTemplate = () => {
    return (
        <>
            <div style={{paddingRight: "25px"}}>
                <div>
                    <Skeleton shape="rectangle" width="10rem" height="4rem" className="mr-2"></Skeleton>
                    <div>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                    </div>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="">
                    <Skeleton width="4rem" height=".5rem"></Skeleton>
                    <Skeleton width="4rem" height=".5rem"></Skeleton>
                </div>
            </div>
        </>
    )
}

export default ReportLoadingTemplate;