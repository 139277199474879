import React from 'react';
import { AuthProvider } from './context/AuthContext';
import Dashboard from './pages/Dashboard';
import Layout from './pages/Layout';
import ToolsPage from './pages/Tools';
import ReportsPage from './pages/Reports';
import ReportsDetail from './pages/ReportsDetail';
import ExplorePage from './pages/Explore';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/Login';
import FaqPage from './pages/Faq';

import PrivateRoute from './routes/PrivateRoute';

import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout className="overflow-hidden h-screen" />} >
                        {/* Protected Routes are wrapped with PrivateRoute */}
                        {/* TODO: Abstract the routes out along with PrivateRoute */}
                        <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="tools" element={<PrivateRoute><ToolsPage /></PrivateRoute>} />
                        <Route path="reports" element={<PrivateRoute><ReportsPage/></PrivateRoute>} />
                        <Route path="campaign/:campaignId" element={<PrivateRoute><ReportsDetail/></PrivateRoute>} />
                        <Route path="explore" element={<PrivateRoute><ExplorePage /></PrivateRoute>} />
                        <Route path="faq" element={<PrivateRoute><FaqPage /></PrivateRoute>} />
                        <Route path="*" element={<ErrorPage />} />
                        <Route path="/login" element={<LoginPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}


export default App;
