import { useEffect, useState} from 'react';
import { Outlet } from "react-router-dom";

import VerticalNav from "../../components/core/VerticalNav";
import Nav from "../../components/core/Nav";

import './styles.css';

const Layout = () => {
  const [isAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  
  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
    <div class="h-full">
        <Nav className="flex-grow-1" />
        <div className="flex">
            <VerticalNav className="bg-primary flex-grow-1 flex-column flex" />
            <div className="p-5">
              <Outlet />
            </div>
        </div>
    </div>
    </>
  )
};

export default Layout;