import { fetchFromApi } from "../../services"

// Sends a JWT from Google to Django and either registers a new
// user or signs in an existing user.
export const logoutUser = () => {
    fetchFromApi("/auth/logout/")
    .then(rs => {
        localStorage.setItem('isAuthenticated', 'false')
    })
    .catch(error => {
        console.log("Something went wrong:", error)
    })
}