import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { getAdvertisers, getAdvertiserCampaigns } from "../../apis/advertisers";
import AdvertiserCampaigns from "./AdvertiserCampaigns";
import { useLocation } from "react-router-dom";


const ExplorePage = () => {
	// TODO: Save state to session storage and hydrate from session storage
	const location = useLocation();
	const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
	const [advertiserCampaigns, setadvertiserCampaigns] = useState(null);
	const [advertisers, setAdvertisers] = useState([]);
	
	useEffect(() => {
		const selectedAdvertiser = location.state?.selectedAdvertiser;
		if (selectedAdvertiser) {
			setSelectedAdvertiser(selectedAdvertiser);
		}
	});

	useEffect(() => {
		const fetchAdvertisers = async () => {
			const response = await getAdvertisers();
			setAdvertisers(response);
		};
		fetchAdvertisers();
	}, []);


	useEffect(() => {
		const fetchadvertiserCampaigns = async () => {
			const response = await getAdvertiserCampaigns(selectedAdvertiser.id);
			setadvertiserCampaigns(response);
		};
		if (selectedAdvertiser) {
			try{
				fetchadvertiserCampaigns();
			} catch (error) {
				console.error("Error fetching advertiser campaigns:", error);
			}
		}
	}, [selectedAdvertiser]);

	return (
		<>
			<Dropdown
				value={selectedAdvertiser}
				onChange={(e) => {
					setSelectedAdvertiser(e.value);
					setadvertiserCampaigns(null);
				}}
				options={advertisers}
				optionLabel="name"
				placeholder="Select an Advertiser"
				filter
				className="w-full md:w-14rem"
			/>
			{selectedAdvertiser && (
				<div>
					<h1>{selectedAdvertiser.name}</h1>
					<AdvertiserCampaigns campaigns={advertiserCampaigns} />
				</div>
			)}
		</>
	);
};

export default ExplorePage;
