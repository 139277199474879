import { useParams } from 'react-router-dom';
import useCampaign from './useCampaign';
import {EblastReport, WebcastReport, SponsoredContentReport, NewsletterAdReport, ProductRoundUpReport, WebsiteAdReport, BreakingNewsReport, LeadGenReport, PartnerSolutionsReport, PodcastVideocastReport} from './ReportTemplates';
import ReportLoadingTemplate from './ReportTemplates/ReportLoadingTemplate' 

const reportComponents = {
    'Eblast': EblastReport,
    'Webcast': WebcastReport,
    'Sponsored Content': SponsoredContentReport,
    'Newsletter Ad': NewsletterAdReport,
    'Product Round Up': ProductRoundUpReport,
    'Website Ad': WebsiteAdReport,
    'Breaking News': BreakingNewsReport,
    'Lead Gen': LeadGenReport,
    'Partner Solutions': PartnerSolutionsReport,
    'Podcast/Videocast': PodcastVideocastReport
};

const ReportsDetail = ({campaignId=null}) => {
    // If campaignId is not passed as a prop, use the URL parameter
    // This allows the component to be used in both Dialog and Route contexts
    const { campaignId: paramCampaignId } = useParams();
    const selectedCampaignId = campaignId || paramCampaignId;
    const { loading, data: reportData } = useCampaign(selectedCampaignId);  // Use the updated hook

    if (loading) return <ReportLoadingTemplate />;
    if (reportData?.error) return <div>Error: Report {reportData?.error}</div>;
    if (!reportData) return <div>No report found.</div>;

    console.log("reportData", reportData);

    // Determine which report component to use based on the product_id
    const ReportComponent = reportComponents[reportData.campaign.product];
    if (!ReportComponent) {
        return <div>Unsupported report type: {reportData.campaign.product}</div>;
    }

    return (
        <>
            <ReportComponent data={reportData} />
            {reportData?.campaign?.google_sheet_report && <a href={reportData?.campaign?.google_sheet_report} rel="noreferrer" target="_blank">View Google Report</a>}
        </> 
    )
}

export default ReportsDetail