import { Dialog } from 'primereact/dialog';
import ReportsDetail from '../../ReportsDetail'

const ReportDialog = ({selectedCampaign, reportVisible, setReportVisible}) => {
    return (
        <Dialog maximizable visible={reportVisible} style={{ width: '75vw' }} onHide={() => setReportVisible(false)}>
            <ReportsDetail campaignId={selectedCampaign?.id} />
        </Dialog>
    );
}
export default ReportDialog;