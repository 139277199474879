import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import useRepData from './useRepData';
import BarChart  from '../../components/charts/BarChart';
import StackedHorizontalBarChart from '../../components/charts/StackedHorizontalBarChart';
import ctLogo from '../../assets/images/ct-icon.png';

const Dashboard = () => {
    const { user, spoofedUser } = useContext(AuthContext);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const repData = useRepData(spoofedUser || user, selectedProduct);
    return (
        <>
            <div className={"grey lighten-4"} style={{padding: 10, borderRadius: 10}}>
            <h3>Dashboard</h3>
            {/* personal dashboard for */}
            <p style={{fontSize: 18}}>Personal dashboard for <strong>{spoofedUser?.username || user.username}</strong></p>
            </div>
            {!repData ? 
                <p>Loading...</p> : 
                ( !repData?.total_campaigns ? 
                    ( repData?.campaigns ? 
                    <>
                        <h4 className={"light-blue-text text-darken-3"}>All {selectedProduct} Campaigns Sold</h4>
                        <a href="#" style={{marginBottom: 10}} onClick={()=> setSelectedProduct(null)}>Go Back</a>
                        <div className={"collection"}>
                        {/* {repData?.campaigns?.map(({advertiser__name, start_date, index}) =>( 
                            <a href={"#"} key={index} className="collection-item">{start_date}: {advertiser__name}</a>
                        ))
                        } */}
                        </div>
                    </> : <p>No campaign data found.</p> )
                    :
                    <>
                    <div className={"row card card-panel"}>
                        <StackedHorizontalBarChart data={null}/>
                    </div>
                    <div className={"row"}>
                        <div className={"col s12 m6 "}>
                            <div className={" card card-panel"}>
                                <h3>Total Sales to Date</h3>
                                <h4 className={"light-green-text text-darken-2"}>$1,368,785</h4>
                            </div>
                            <div className={"card card-panel"}>
                                <h3>Sales Breakdown by Product</h3>
                                <h6>Total campaigns sold: <strong>{repData.total_campaigns}</strong></h6> 
                                {/* {repData.campaigns_by_product.map((campaign, index) => (
                                    <h6 className={"light-blue-text text-darken-3"} key={index}>{campaign.name}: {campaign.count}</h6>
                                ))} */}
                                {
                                    selectedProduct ? 
                                        <>
                                        <p>Selected product: {selectedProduct}</p> 
                                        <a href="#" onClick={()=> setSelectedProduct(null)}>Go Back</a>
                                        </>:
                                        <BarChart campaigns={repData.campaigns_by_product} setSelectedProduct={setSelectedProduct} />                        
                                }
                            </div>
                        </div>
                        <div className={"col s12 m6 "} style={{paddingLeft: 20}}>
                            <div className={" card card-panel"}>
                                <h3>Currently Running Campaigns</h3>
                                <ul>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-down"}></i>Dedicated Emails - 2
                                        <br/>
                                        <a href={"/reports/berje"} style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> DSM Nutritional Products: Started 04/26/2024</a>
                                        <br/>
                                        <a href="#" style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> McKenna Labs: Started 04/25/2024</a>
                                        <br/>
                                        <a href="#" style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> Hallstar: Started 04/15/2024</a>
                                    </li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Sponsored Content - 5</li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Partner Solutions - 1</li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Web Ads - 8</li>
                                </ul>
                            </div>
                            <div className={" card card-panel"}>
                                <h3>Recently Finished Campaigns</h3>
                                <ul>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-down"}></i>Dedicated Emails - 2
                                        <br/>
                                        <a href="#" style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> Berje: Started 05/01/2024</a>
                                        <br/>
                                        <a href="#" style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> McKenna Labs: Started 04/25/2024</a>
                                        <br/>
                                        <a href="#" style={{fontSize: 18, marginLeft: 10}}><img alt="logo" src={ctLogo} height={25} /> Hallstar: Started 04/15/2024</a>
                                    </li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Sponsored Content - 5</li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Partner Solutions - 1</li>
                                    <li style={{fontSize: 22}}><i className={"pi pi-angle-right"}></i>Web Ads - 8</li>
                                </ul>
                            </div>
                            <div className={" card card-panel"}>
                                <h3>Top 5 Advertisers</h3>
                                <ul>
                                    <li><i className={"pi pi-angle-down"}></i>AccuPac
                                        <br/>
                                        <span style={{fontSize: 12, marginLeft: 10}}>Campaigns: 12</span>
                                        <br/>
                                        <span style={{fontSize: 12, marginLeft: 10}}>Total Spend: $102k</span>
                                    </li>
                                    <li><i className={"pi pi-angle-right"}></i>Acme</li>
                                    <li><i className={"pi pi-angle-right"}></i>Berje</li>
                                    <li><i className={"pi pi-angle-right"}></i>BASF</li>
                                    <li><i className={"pi pi-angle-right"}></i>Rhonda Allison</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                )
            }
        </>
    )
}

export default Dashboard;