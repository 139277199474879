import { useState, useEffect } from 'react';
import { getCampaign } from '../../apis/advertisers';

const useCampaign = (campaignId) => {
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCampaign = async () => {
            setLoading(true);   // Start loading before fetching data
            setError(null);    // Reset errors
            try {
                const response = await getCampaign(campaignId);
                console.log("response", response)
                setCampaign(response);
            } catch (err) {
                setError(err.message || 'Failed to fetch campaign'); // Handle errors if fetch fails
                setCampaign(null); // Ensure campaign is reset on error
            }
            setLoading(false);  // Stop loading after fetching data
        };

        if (campaignId) {
            fetchCampaign();
        }
    }, [campaignId]);

    return { loading, error, data: campaign };
}

export default useCampaign;