const ToolsPage = () => {
    return (
        <div>
            <h1>Tools</h1>
            <p>Tools and utilities for various departments throughout Allured.</p>
            {/* materialize card that has an external link to http://www.google.com */}
            <div className="row">
                <div className="col s12 m6">
                    <div className="card">
                            <div className="card-image">
                                <img src="https://www.udacity.com/blog/wp-content/uploads/2020/06/HTML_Blog-scaled.jpeg" alt="TextAd Builder" style={{height: "200px"}} />
                                <span className="card-title">TextAd Builder</span>
                            </div>
                        <div className="card-content">
                            <p>Generate HTML for newsletter text ads.</p>
                        </div>
                        <div className="card-action">
                            <a href="https://intranet.allured.com/builders/adbuilder/" target="_blank">Go to TextAd Builder</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ToolsPage;