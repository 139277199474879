import React from 'react';
import { Link } from 'react-router-dom';

const VerticalNav = () => {
    const menuItems = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            link: '/',
        },
        {
            title: 'Reports',
            icon: 'description',
            link: '/reports',
        },
        {
            title: 'Explore',
            icon: 'explore',
            link: '/explore',
        },
        {
            title: 'Tools',
            icon: 'build',
            link: '/tools',
        },
        {
            title: 'Settings',
            icon: 'settings',
            link: '/settings',
        },
        {
            title: 'FAQ',
            icon: 'info',
            link: '/faq',
        },
        {
            title: 'Help',
            icon: 'bug_report',
            link: '/help',
        },
    ]
    return (
        <div className="bg-blue-600 pr-8 h-screen">
            <ul className="list-none text-white" id="my-sidenav">
                {menuItems.map((item, index) => (
                    <li key={index} className="flex pb-5 text-white">
                        <Link to={item.link} className="flex items-center text-white  no-underline">
                            <i className="material-icons mr-2 text-white  no-underline">{item.icon}</i>
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default VerticalNav;
