import { Accordion, AccordionTab } from 'primereact/accordion';

const FaqPage = () => {
    return (
        <div>
        <h1>Frequently Asked Questions</h1>
        <Accordion>
            <AccordionTab header="What is the meaning of life?">
                <p>The meaning of life is a philosophical question concerning the significance of life or existence in general.</p>
            </AccordionTab>
            <AccordionTab header="What is the airspeed velocity of an unladen swallow?">
                <p>The airspeed velocity of an unladen swallow is 24 miles per hour or 11 meters per second.</p>
            </AccordionTab>
            <AccordionTab header="What is the answer to the ultimate question of life, the universe, and everything?">
                <p>The answer to the ultimate question of life, the universe, and everything is 42.</p>
            </AccordionTab>
        </Accordion>
        </div>
    );
}

export default FaqPage;