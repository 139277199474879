import React from 'react';

const ErrorPage = ({element}) => {
    console.log('ErrorPage element:', element);
    return (
        <div>
            <h1>404 Not Found</h1>
            <p>The URL was not found.</p>
            <br />
            <p> Why you do this?</p>
        </div>
    );
};

export default ErrorPage;
