import { fetchFromApi } from "../../services";

export const fetchRepData = async (sold_by_email, product) => {
    try {
        const response = await fetchFromApi("/sales-analytics", { sold_by: sold_by_email, product: product });
        console.log('Successfully fetched rep data:', response.data);
        return response.data; // Return data to the caller
    } catch (error) {
        console.log("Something went wrong:", error);
        return null; // Return null or appropriate error handling
    }
}