import {fetchFromApi}  from '../services'

export const getAdvertisers = async () => {
    return fetchFromApi("/advertisers/");
}
export const getAdvertiserCampaigns = async (advertiser_id) => {
    return fetchFromApi(`/advertisers/${advertiser_id}/campaigns/`);
}
// export const getAdvertiserCampaigns = async (advertiser_id) => {
//     try {
//         const response = await axios.get(BASE_API_URI + `/advertisers/${advertiser_id}/campaigns/`, { withCredentials: true });
//         return response.data; // Return data to the caller
//     } catch (error) {
//         console.log("Something went wrong:", error);
//         return null; // Return null or appropriate error handling
//     }
// }
export const getCampaigns = async () => {
    return fetchFromApi("/getcampaigns/");
}
// export const getCampaigns = async () => {
//     try {
//         const response = await axios.get(
//             BASE_API_URI + "/campaigns/", 
//             { 
//                 withCredentials: true,
//             }
//         );
//         console.log("response.data", response.data)
//         return response.data; // Return data to the caller
//     } catch (error) {
//         console.log("Something went wrong:", error);
//         return null; // Return null or appropriate error handling
//     }
// };
export const getCampaign = async (campaignId) => {
    return fetchFromApi(`/reports/`, { campaign_id: campaignId });
}
// export const getCampaign = async (campaignId) => {
//     try {
//         const response = await axios.get(
//             BASE_API_URI + `/reports`, 
//             { 
//                 withCredentials: true, 
//                 params: { 
//                     campaign_id: campaignId 
//                 }
//             });
//         return response.data; // Return data to the caller
//     } catch (error) {
//         console.log("Something went wrong:", error);
//         return null; // Return null or appropriate error handling
//     }
// }
export const getCampaignOverviewReport = async ({advertiser_id, year, brand, userEmail}) => {
    return fetchFromApi(`/overview/generateOverviewReport`, { advertiser_id: advertiser_id, year: year, brand: brand, user: userEmail });
}
// export const getCampaignOverviewReport = async ({advertiser_id, year, brand}) => {
//     try {
//         const response = await axios.get(
//             BASE_API_URI + `/generateOverviewReport`, 
//             { 
//                 withCredentials: true, 
//                 params: { 
//                     advertiser_id: advertiser_id,
//                     year: year,
//                     brand: brand
//                 }
//             });
//         return response.data; // Return data to the caller
//     } catch (error) {
//         console.log("Something went wrong:", error);
//         return null; // Return null or appropriate error handling
//     }
// }