import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Panel } from "primereact/panel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useState, useContext, useRef } from "react";
import { getCampaignOverviewReport } from "../../../apis/advertisers";
import { AuthContext } from "../../../context/AuthContext";
import { Toast } from "primereact/toast";
import ReportDialog from "../../Reports/ReportDialog";

const CampaignsByProduct = ({ campaigns }) => {
	const campaigns_by_product = campaigns?.reduce((acc, campaign) => {
		if (!acc[campaign.product]) {
			acc[campaign.product] = [];
		}
		acc[campaign.product].push(campaign);
		return acc;
	}, {});
	const chartData = !campaigns
		? {}
		: {
				labels: Object.keys(campaigns_by_product),
				datasets: [
					{
						data: Object.values(campaigns_by_product).map(
							(campaigns) => campaigns.length
						),
						backgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
						],
						hoverBackgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
						],
					},
				],
		  };
	return (
		<Chart
			type="doughnut"
			data={chartData}
			style={{ height: "300px" }}
			className="w-full md:w-30rem"
		/>
	);
};
const CampaignDataTable = ({ campaigns }) => {
	const [selectedCampaign, setSelectedCampaign] = useState(null);
	const [reportVisible, setReportVisible] = useState(false);

	const columns = [
		{ field: "product", header: "Product" },
		{ field: "start_date", header: "Start Date" },
		{ field: "brand", header: "Brand" },
        { field: "sold_by", header: "Sold By" },
	];
	const onRowClick = (e) => {
		console.log("Row clicked", e);
		setSelectedCampaign(e.data);
		setReportVisible(true);
	}
	return (
		<>
			<DataTable selectionMode="single" onRowClick={onRowClick} value={campaigns} className="w-full" showGridlines>
				{columns.map((col) => (
					<Column field={col.field} header={col.header} key={col.field} />
				))}
			</DataTable>
			<ReportDialog selectedCampaign={selectedCampaign} reportVisible={reportVisible} setReportVisible={setReportVisible} />
		</>
	);
};

const AdvertiserCampaigns = ({ campaigns }) => {
	// Brands is a list of unique brands from the campaigns the advertiser has run
	const brands = campaigns ? Array.from(new Set(campaigns?.map(({brand}) => brand)), (brand) => ({ value: brand, label: brand })) : [];
	const { user } = useContext(AuthContext);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const currentYear = new Date().getFullYear().toString();
	const [selectedYear, setSelectedYear] = useState("2024");
	const toast = useRef(null);

	// list of years since 2023 to present year
	const yearOptions = Array.from({length: currentYear - 2022}, (_, i) => ({ value: (i + 2023).toString(), label: (i + 2023).toString() }));
	const handleDownload = async () => {
		// add invalid prop to brand dropdown if no brand is selected
		if (!selectedBrand) {
			document.getElementById("brandDropdown").classList.add("p-invalid");
			toast.current.show({severity: "error", summary: "Error", detail: "Brand is required to generate report"});
			return;
		}
		const rs = await getCampaignOverviewReport({advertiser_id: campaigns[0].advertiser_id, year: selectedYear, brand: selectedBrand, userEmail: user?.username});
		if (rs.error) {
			toast.current.show({severity: "error", summary: "Error", detail: "Failed to generate report"});
			return;
		}
		toast.current.show({severity: "success", summary: "Success", detail: "Report will be emailed to " + user?.username + " when ready." });
	}

	return (
		<div className="flex">
			<Toast ref={toast} />
			{campaigns ? (
				<>
					<div className="row">
						<div className="col s4">
							<Panel header="Campaigns by Product">
								<CampaignsByProduct campaigns={campaigns} />
							</Panel>
						</div>
						<div className="col s4">
							<Panel header="Campaigns by Status">
								<Accordion>
									<AccordionTab
										header={
											<div>
												<span>In-Progress</span>
												<Badge value={
                                                    campaigns.filter(
                                                        (campaign) => campaign.status === "inprogress"
                                                    ).length
                                                } className="right" />
											</div>
										}
									>
										<CampaignDataTable
											campaigns={campaigns.filter(
												(campaign) => campaign.status === "inprogress"
											)}
										/>
									</AccordionTab>
									<AccordionTab header={
                                        <div>
                                            <span>Upcoming</span>
                                            <Badge value={
                                                campaigns.filter(
                                                    (campaign) => campaign.status === "upcoming"
                                                ).length
                                            } className="right" />
                                        </div>
                                    }>
										<CampaignDataTable
											campaigns={campaigns.filter(
												(campaign) => campaign.status === "upcoming"
											)}
										/>
									</AccordionTab>
									<AccordionTab header={
                                        <div>
                                            <span>Completed</span>
                                            <Badge value={
                                                campaigns.filter(
                                                    (campaign) => campaign.status === "completed"
                                                ).length
                                            } className="right" />
                                        </div>
                                    }>
										<CampaignDataTable
											campaigns={campaigns.filter(
												(campaign) => campaign.status === "completed"
											)}
										/>
									</AccordionTab>
								</Accordion>
							</Panel>
						</div>
						<div className="col s4 center">
							<Panel header="Get Overview Report">
								<div className="row">
									<Dropdown options={yearOptions} value={selectedYear} onChange={(e) => setSelectedYear(e.value)}/>
								</div>
								<div className="row">
									<Dropdown id="brandDropdown" placeholder="Select Brand" required options={brands} value={selectedBrand} onChange={(e) => setSelectedBrand(e.value)}/>
								</div>
								<Button label="Download" icon="pi pi-download" onClick={() => handleDownload()} />
							</Panel>
						</div>
					</div>
				</>
			) : (
				<ProgressSpinner
					style={{ width: "50px", height: "50px" }}
					strokeWidth="8"
					fill="var(--surface-ground)"
					animationDuration=".5s"
				/>
			)}
		</div>
	);
};

export default AdvertiserCampaigns;
