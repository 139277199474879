
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { getCampaigns } from '../../../apis/advertisers';

import ReportDialog from '../ReportDialog';

export default function ReportFilter() {
    // TODO: Save state to session storage and hydrate from session storage
    const [campaigns, setCampaigns] = useState(null);
    const [reportVisible, setReportVisible] = useState(false);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [brands] = useState([
        { name: 'Beauty Launchpad', image: 'amyelsner.png' },
        { name: 'Cosmetics & Toiletries', image: 'annafali.png' },
        { name: 'GCI Magazine', image: 'asiyajavayant.png' },
        { name: 'Medical Esthetics', image: 'onyamalimba.png' },
        { name: 'Nail Pro', image: 'ionibowcher.png' },
        { name: 'Perfumer & Flavortist', image: 'bernardodominic.png' },
        { name: 'Skin Inc.', image: 'elwinsharvill.png' },
        { name: 'Well Spa', image: 'ivanmagalhaes.png' },
    ]);
    const [salesReps] = useState([
        { name: 'Kim Jednachowski', image: 'kimjednachowski.png' },
        { name: 'Jolly Patel', image: 'jollypatel.png' },
        { name: 'Angelina Lutsenko', image: 'angelinalutsenko.png' },
        { name: 'Barbara Carreon', image: 'barbaracarreon.png' },
        { name: 'Paige Crist', image: 'paigecrist.png' },
        { name: 'Perri Polowy', image: 'perripolowy.png' },
        { name: 'Shamella Anderson', image: 'shamellaanderson.png'},
    ]);

    const [products] = useState(["Sponsored Content", "Newsletter Ad", "Eblast", "Product Round Up", "Partner Solutions", "Webcast", "Website Ad", "Breaking News", "Lead Gen", "Ebook", "Podcast/Videocast" ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCampaigns();
                data?.results ? setCampaigns(getCampaignsData(data?.results)) : setCampaigns(getCampaignsData(data));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching advertisers:", error);
            }
        };

        fetchData();
        initFilters();
    }, []);

    const getCampaignsData = (data) => {
        // convert date strings to Date objects
        return data;
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button className="" type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            advertiser: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            brand: { value: null, matchMode: FilterMatchMode.IN },
            sold_by: { value: null, matchMode: FilterMatchMode.IN },
            product: { value: null, matchMode: FilterMatchMode.EQUALS },
            start_date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };

    const brandBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" style={{ width: '24px' }} />
                <span>{rowData?.brand}</span>
            </div>
        );
    };

    const brandRowFilterTemplate = (options) => {
        return (
            <MultiSelect
            value={options.value} // Ensure it's always an array, even if initially null or undefined
            options={brands.map(brand => brand.name)}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Any"
            className="p-column-filter"
            style={{ minWidth: '14rem' }}
        />
        );
    };

    
    const productRowFilterTemplate = (options) => {
        const sortedProducts = products.sort();
        return (
            <Dropdown
            value={options.value}
            options={sortedProducts.map(product => ({ label: product, value: product }))}
            placeholder="Any"
            style={{ width: '100%' }}
            onChange={(e) => {
                options.filterApplyCallback(e.value)
            }}
            showClear
            checkmark={true}
            />
        );
    };

    const soldbyRowFilterTemplate = (options) => {
        return (
            <MultiSelect 
                value={options.value}
                options={salesReps.map(rep => rep.name)}
                placeholder="Any"
                className="p-column-filter browser-default"
                style={{ minWidth: '14rem' }}
                maxSelectedLabels={1}
                onChange={(e) => {
                    options.filterApplyCallback(e.value)
                }}
            />
        );
    };


    const header = renderHeader();
    return (
        <div className="card">
            <DataTable 
                selectionMode="single" 
                onSelectionChange={(e) => {
                    setSelectedCampaign(e.value)
                    setReportVisible(true)
                }} 
                metaKeySelection={false} 
                removableSort 
                selection={selectedCampaign} 
                value={campaigns} 
                paginator 
                showGridlines 
                rows={10} 
                dataKey="id" 
                filters={filters} 
                filterDisplay="row" 
                loading={loading}
                globalFilterFields={['advertiser', 'brand', 'sold_by', 'product', 'start_date']} 
                header={header} 
                emptyMessage="No customers found."
                stripedRows
            >
                <Column field="start_date" filterPlaceholder="Search by campaign date" header="Campaign Date" filter sortable style={{ minWidth: '12rem' }} showFilterMenu={false} />
                <Column field="advertiser" header="Advertiser" filter filterPlaceholder="Search by advertiser name" sortable style={{ minWidth: '12rem' }} showFilterMenu={false} />
                <Column field="product" header="Product" filter filterElement={productRowFilterTemplate} sortable style={{ minWidth: '12rem' }} showFilterMenu={false}/>
                <Column header="Brand" field="brand" filterField="brand" showFilterMenu={false} style={{ minWidth: '12rem' }} filterElement={brandRowFilterTemplate} body={brandBodyTemplate} sortable filter />
                <Column field="sold_by" header="Sold By" filterField='sold_by' filterElement={soldbyRowFilterTemplate} filter sortable style={{ minWidth: '12rem' }} showFilterMenu={false} />
            </DataTable>
            <ReportDialog selectedCampaign={selectedCampaign} reportVisible={reportVisible} setReportVisible={setReportVisible} />
        </div>
    );
}
        