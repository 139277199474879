import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ redirectTo: location }} />
    );
};


export default PrivateRoute;
