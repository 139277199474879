import './main.css'
import ReportFilter from './ReportFilter';

const Reports = () => {
    return (
        <>
            <ReportFilter />
        </>
    )
}

export default Reports;