import React, { useContext } from 'react';

import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

import { AuthContext } from '../../../context/AuthContext';

import logo from '../../../assets/images/logo-full.jpg'; // Import the image
import './styles.css';

const Nav = () => {
    const { user, logout, spoofedUser, setSpoofedUser } = useContext(AuthContext);

    // Greeting for loged in user in menubar item
    const items = [
        {
            label: `Welcome, ${spoofedUser?.username || user?.username}`,
            icon: 'pi pi-fw pi-user',
        },
        {
            label: 'Dev',
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Spoof User',
                    icon: 'pi pi-fw pi-users',
                    items: [
                        {
                            label: 'Jolly Patel',
                            icon: 'pi pi-fw pi-user',
                            id: 'jpatel@allured.com',
                            command: ({item}) => setSpoofedUser({username: item.id})
                        },
                        {
                            label: 'Kim Jednachowski',
                            id: 'kjednachowski@allured.com',
                            icon: 'pi pi-fw pi-user',
                            command: ({item}) => setSpoofedUser({username: item.id})
                        },
                        {
                            label: 'Reset User',
                            icon: 'pi pi-fw pi-user',
                            id: user.username,
                            command: ({item}) => setSpoofedUser({username: item.id})
                        },
                    ]
                },
            ]
        }
    ];
    
    const end = <Button label="Logout" icon="pi pi-power-off" onClick={logout} />;

    const start = <img alt="Allured Logo" src={logo} height="40" className="p-mr-2"></img>;
    
    return <Menubar className="flex flex-grow-1" model={items} start={start} end={end} />;
};

export default Nav;