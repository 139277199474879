
import { Chart } from 'primereact/chart';

const BarChart = ({campaigns, setSelectedProduct}) => {
    var campaignCountsByProduct = {}
        campaigns.forEach(campaign => {
            campaignCountsByProduct[campaign.name] = campaign.count
        })
    
    const chartData = {
        labels: Object.keys(campaignCountsByProduct),
        datasets: [
            {
            label: "Campaigns Sold by Product",
            data: Object.values(campaignCountsByProduct),
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#006384',
                '#B7A2EB',
                '#AFE3C6',
                '#FF6D12',
                '#36AABC',
                '#FFC09E',
                // Add more colors as needed...
            ],
            hoverBackgroundColor: [
                'lightblue',
                // Add more colors as needed...
            ]
            }
        ]
    };
    
    const chartOptions = {
        animation: {
          duration: 300  // duration in milliseconds
        },
        onClick: function(evt, item) {
            const label =  chartData.labels[item[0].index]
            setSelectedProduct(label)
        }
      };
    return (
        <div>
            <Chart type="bar" data={chartData} options={chartOptions} className="" style={{ maxWidth: '500px', maxHeight: '500px', textAlign: 'center'}} />
        </div>
    )
}

export default BarChart;