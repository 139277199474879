import { Chart } from 'primereact/chart';

const StackedHorizontalBarChart = ({ data }) => {
    const chartData = {
        labels: ['Business Split'], // Single category label
        datasets: [
            {
                label: 'New Business',
                data: [15], // Percentage of new business
                backgroundColor: '#42A5F5',
            },
            {
                label: 'Repeat Business',
                data: [85], // Percentage of repeat business
                backgroundColor: '#66BB6A',
            },
        ],
    };

    const options = {
        indexAxis: 'y', // This makes the bar chart horizontal
        aspectRatio: 14,
        scales: {
            x: {
                stacked: true, // Stack the horizontal bars
                ticks: {
                    callback: function(value) {
                        return value + "%"; // Append '%' to the scale labels
                    }
                }
            },
            y: {
                stacked: true, // Necessary for horizontal stacking
            },
        },
        plugins: {
            legend: {
                display: true, // Display legend (optional)
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.x !== null) {
                            label += context.parsed.x + '%';
                        }
                        return label;
                    }
                }
            }
        },
    };

    return (
        <div>
            <Chart type="bar" data={chartData} options={options} style={{height: 100}}/>
        </div>
    );
};

export default StackedHorizontalBarChart;
