import { useState, useEffect } from 'react';
import  { fetchRepData }  from '../../apis/user/fetchRepData';

const useRepData = ({username: sold_by_email}, selectedProduct) => {
    const [repData, setRepData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchRepData(sold_by_email, selectedProduct);
                setRepData(data);
            } catch (error) {
                console.error(error);
            }
        };
        
        fetchData();
    }, [sold_by_email, selectedProduct]);

    return repData;
}

export default useRepData;